.splide.is-active {
  padding-bottom: 4rem;
}
.splide.is-active .splide__slide {
  display: flex;
  justify-content: center;
}
.splide.is-active .splide__pagination {
  bottom: 1.5em;
}
.splide.is-active .splide__pagination__page {
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: var(--color-neutral-1100);
}
.splide.is-active .splide__pagination__page.is-active {
  background-color: var(--color-blue-1100);
}
.splide.is-active .splide__pagination__page:hover {
  background-color: var(--color-blue-1100);
}

.hp-carousel {
  --bubble-height: 100px;
  padding-block: 2rem;
  background-color: #fff;
}
.hp-carousel__title {
  margin-bottom: 2rem;
}
.hp-carousel .slide__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.hp-carousel .slide__bubble {
  position: relative;
  margin-top: -40px;
  min-height: var(--bubble-height);
}
.hp-carousel .slide__img {
  width: 100%;
  display: flex;
  justify-content: center;
}
.hp-carousel .slide__content {
  color: var(--color-blue-1100);
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .hp-carousel {
    --bubble-width: 400px;
    --img-height: 493px;
    --bubble-height: 300px;
  }
  .hp-carousel .slide__bubble {
    margin-top: -20%;
  }
  .hp-carousel .slide__img img {
    height: var(--img-height);
    width: auto;
  }
  .hp-carousel .slide__container {
    width: 485px;
  }
}
@media (min-width: 1024px) {
  .hp-carousel {
    --bubble-width: 220px;
  }
  .hp-carousel .slide__container {
    flex-direction: row;
    width: 855px;
  }
  .hp-carousel .slide__img {
    width: 50%;
  }
  .hp-carousel .slide__img img {
    width: 100%;
    height: auto;
  }
  .hp-carousel .slide__bubble {
    position: absolute;
    margin-top: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 0;
  }
  .hp-carousel .slide__content {
    width: 50%;
    padding-left: calc(var(--bubble-width) / 2 + 2rem);
    padding-top: 0;
  }
}
@media (min-width: 1440px) {
  .hp-carousel .slide__container {
    width: 966px;
  }
}

.hb-bubble {
  background-color: var(--color-brand-900);
  color: #fff;
  width: var(--bubble-width, 190px);
  padding: var(--space-24);
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hb-bubble::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  top: 99%;
  z-index: 1;
  left: 32px;
  border: 14px solid;
  border-color: var(--color-brand-900) transparent transparent var(--color-brand-900);
  transform: rotate(-270deg);
}
.hb-bubble__icon {
  width: 41px;
  height: 41px;
}
.hb-bubble__icon img {
  height: auto;
}
@media (max-width: 479px) {
  .hb-bubble__icon {
    display: none;
  }
}
.hb-bubble__title {
  font-size: 1.25rem;
  margin-block: 1rem;
  color: inherit;
  font-weight: 500;
}
@media (max-width: 479px) {
  .hb-bubble__title {
    margin-top: 0;
  }
}
.hb-bubble p {
  font-size: 0.875rem;
  line-height: 1.3;
}